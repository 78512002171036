import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";

const initialState = {
  benDeleteSuccess: false,
  error: "",
  loading: false,
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
};

export const addNewBeneficiary = createEncryptedAsyncThunk(
  "beneficiary/addNewBeneficiary",
  "transaction/RegisterBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // mobile: params.customerMobileNumber,
      // benename: params.accountHolderName,
      // bankid: `${params.selectBank.value}`,
      // accno: params.accountNumber,
      // ifsccode: params.IFSCcode,
      // verified: "0",
      // gst_state: "Maharshtra",
      // dob: "1992-04-05",
      // address: "AT-123",
      // pincode: "400706",

      userId: params.currentUser.userGuid,
      benemobile: params.customerMobileNumber,
      benename: params.accountHolderName,
      bankid: `${params.selectBank.value}`,
      accno: params.accountNumber,
      ifsccode: params.IFSCcode,
      verified: "0",
      dob: "1992-04-05",
      address: "AT-23",
      branch_address: "Thane, mumbai",
      pincode: "400706",
      serviceid: params.serviceId.serviceId,
      txnType: "IMPS",
      email: "example2gmail.com",
      customer_id: params.custId,
    }),
  }
);

export const addCustomerEKYC = createEncryptedAsyncThunk(
  "beneficiary/addCustomerEKYC",
  "account/CustomerEKYC",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      customerMobileNo: params.KycMobileNumber,
      serviceId: params.serviceId.serviceId,
      longitude: `${params.lng}`,
      latitude: `${params.lat}`,
      aadharNo: params.KycAdharNumber,
      biometric_data: params.captureData,
      platform: "WEB",
    }),
  }
);

export const addNewBeneficiarySelf = createEncryptedAsyncThunk(
  "beneficiary/addNewBeneficiarySelf",
  "account/CreateBenificiary",
  {
    prepareBody: (params) => ({
      remt_mobileno: params.Mobile.customerMobileNumber,
      cust_id: params.custId,
      userID: params.currentUser.userGuid,
      account_holder: params.beneBificiaryName,
      accountnumber: params.accountNumber,
      addresss: "At-123",
      ifsc: params.IfscCode,
      bank_id: Number(params.selectBank.value1),
      bank_name: params.selectBank.label,
      branch_address: "At-123",
      mobileno: params.Mobile.customerMobileNumber,
      reference_benificiary_id: `${params.res.bene_id}`,
    }),
  }
);
export const addNewBeneficiarySelfIservue = createEncryptedAsyncThunk(
  "beneficiary/addNewBeneficiarySelfIservue",
  "transaction/RegisterBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // remt_mobileno: params.Mobile.customerMobileNumber,
      // cust_id: params.custId,
      // account_holder: params.beneBificiaryName,
      // accountnumber: params.accountNumber,
      // addresss: "At-123",
      // ifsc: params.IfscCode,
      // bank_id: Number(params.selectBank.value1),
      // bank_name: params.selectBank.label,
      // branch_address: "At-123",
      // mobileno: params.Mobile.customerMobileNumber,
      // reference_benificiary_id: "",

      userId: params.currentUser.userGuid,
      benemobile: params.cutomerMobile,
      benename: params.beneBificiaryName,
      bankid: params.selectBank,
      accno: params.accountNumber,
      ifsccode: params.IfscCode,
      verified: "0",
      dob: "1990-12-20",
      address: "At-123",
      branch_address: "At-123",
      pincode: "413604",
      serviceid: params.serviceId.serviceId,
      txnType: "IMPS",
      customer_id: params.custId,
    }),
  }
);
export const ValidateUserToken = createEncryptedAsyncThunk(
  "beneficiary/ValidateUserToken",
  "Token/ValidateUserToken",
  {
    transUrl: process.env.REACT_APP_API_TOKENSERVICE,
    prepareBody: (params) => ({
      token: params.Usertoken,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const getServiceNotification = createEncryptedAsyncThunk(
  "beneficiary/getServiceNotification",
  "account/GetNotificationDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roles: params.roleId,
      serviceId: params.serviceId ? params.serviceId : [],
      city: [0],
      states: `${params.loginData.state_ID}`,
      languages: "",
      startDate: null,
      endDate: null,
    }),
  }
);
export const verifyBeneficiayForAdd = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiayForAdd",
  "Modules/VerifyBankDetails",
  {
    prepareBody: (params) => ({
      ifsc: params.IfscCode,
      accountNumber: params.accountNumber,
      id: 0,
      beneficiary_Name: params.beneBificiaryName,
      iS_Verify: 0,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const BeneficiaryDelete = createEncryptedAsyncThunk(
  "beneficiary/BeneficiaryDelete",
  "account/DeleteBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: Number(params.serviceId.serviceId),
      mobile: params.data.remt_mobileno,
      bene_id: `${params.data.id}`,
    }),
  }
);
export const verifyBeneficiayDetails = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiayDetails",
  "transaction/VerifyBeneficiaryTransaction",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // serviceId: params.serviceId.serviceId,
      // wallettype: 1,
      // bene_id: Number(params.body.benificiary_Id),
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      wallettype: 1,
      beneficiary_ID: Number(params.body.id),
      // agentid: "",
      // senderMobileNumber: params.Mobile,
      // username: "",
      latitude: String(params.lat),
      longitude: String(params.lng),
      otp: "",
    }),
  }
);
export const verifyBeneficiayDetailsForISRU = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiayDetailsForISRU",
  "account/ISU_VerifyBeneficiaryTransaction",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.currentService.serviceId,
      wallettype: 1,
      beneficiary_ID: params.body.benificiary_Id, //Number(params.body.benificiary_Id),
      tpin: "1234",
      username: "", // params.body.account_holder,
    }),
  }
);
export const setOTP = createEncryptedAsyncThunk(
  "beneficiary/setOTP",
  "Service/TwoWayAuthentication",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      type: "MOBILE_VERIFICATION",
      mode: {
        mode1: "SMS",
        mode2: "",
      },
      auth_mode: 0,
      receiverDetails: {
        mobileNo: params.Mobile.customerMobileNumber,
        emailId: "",
      },
      ipaddress: params.location.ip,
      substitutes: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    }),
  }
);
export const verifyOtp = createEncryptedAsyncThunk(
  "beneficiary/verifyOtp",
  "Service/VerifyPreUserOTP",
  {
    prepareBody: (params) => ({
      otp: params.otp,
      auth_mode: 1,
      type: "MOBILE_VERIFICATION",
      mode: {
        mode1: "SMS",
        mode2: "",
      },
      receiverDetails: {
        mobileNo: params.Mobile.customerMobileNumber,
        emailId: "",
      },
    }),
  }
);
export const customerAdd = createEncryptedAsyncThunk(
  "beneficiary/customerAdd",
  "transaction/CreateCustomer",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      customerMobileNo: params.mobile,
      customerFirstName: params.fname,
      customerLastName: params.lname,
      dob: "1992-12-20",
      address: {
        country: "INDIA",
        city: "Mumbai",
        // state: params.selectState.label,
        // pincode: params.pincode,
        // district: params.selectState.label,
        state: "Maharashtra",
        pincode: "453345",
        district: "Thane",
        area: "Thane",
      },
      skipverification: "",
      email: "example@gmail.com",
      serviceId: params.serviceId.serviceId,
      // ...(params.serviceId.serviceId === 15
      //   ? {
      //       internalVerification: {
      //         otpVerification: true,
      //         otp: params.OTP || "",
      //       },
      //     }
      //   : null),
      ...(params.serviceId.serviceId === 19 && {
        internalVerification: {
          otpVerification: true,
          otp: params.OTP || "",
        },
      }),

      // verification: {
      //   otpVerification: true,
      //   otp: "",
      // },
      // paysprintCreateCustomer: {
      //   otp: "",
      //   gst_state: "",
      //   stateresp: "",
      //   bank3_flag: "",
      // },
      // billavenueCreateCustomer: {
      //   txn_type: "",
      //   otp: "",
      // },
      // {params.serviceId.serviceId === 15 ? internalVerification: {
      //   otpVerification: true,
      //   otp: params.OTP === "" ? "" : params.OTP,
      // }, : null}
      // ekoCreateCustomer: {
      //   otp: params.OTP === "" ? "" : params.OTP,
      //   skipverification: "false",
      // },
    }),
  }
);
export const getRecentTransactionByUser = createEncryptedAsyncThunk(
  "beneficiary/getRecentTransactionByUser",
  "account/RecentTransactionByUser",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      recordLimit: params.recordLimit || 10,
      accountnumber: params.accountnumber || ''
    }),
  }
);
export const customerAddAction = createEncryptedAsyncThunk(
  "beneficiary/customerAddAction",
  "transaction/CreateCustomer",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => {
      let body = {
        userId: params.currentUser.userGuid,
        customerMobileNo: params.customerMobileNo,
        customerFirstName: params.customerFirstName,
        customerLastName: params.customerLastName,
        dob: "2000-01-15T13:25:56.262Z",
        email: "example@gmail.com",
        serviceId: params.serviceId.serviceId,
        ekyc_id: params.ekyc_id || "",
        otp_ref: params.otp_reference || "",
        aadharNo: params.aadharNo || "",
        otp: params.otp || "",
        internalVerification: {
          otpVerification: true,
          otp: params.otp || "",
        },
        // dob: params.dob,
        // address: {
        //   country: "INDIA",
        //   city: params.city,
        //   state: params.selectState,
        //   pincode: params.pincode,
        //   district: params.selectDist,
        //   area: params.address,
        // },

        verification: {
          otpVerification: true,
          otp: params.otp || "",
        },
        // ekoCreateCustomer: {
        //   otp_ref_id: "",
        //   otp: "",
        //   skipverification: "true",
        // },
      };
      return body;
    },
  }
);
export const verifyOTPCustomerAction = createEncryptedAsyncThunk(
  "beneficiary/verifyOTPCustomerAction",
  "transaction/CreateCustomer",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      customerMobileNo: params.customerMobileNo,
      customerFirstName: params.customerFirstName,
      customerLastName: params.customerLastName,
      dob: "1992-12-20",
      address: {
        country: "INDIA",
        city: "Mumbai",
        state: "Maharashtra",
        pincode: "413604",
        district: "Thane",
        area: "Thane",
      },
      email: "example@gmail.com",
      serviceId: params.serviceId.serviceId,
      ekyc_id: params.ekyc_id || "",
      otp_ref: params.otp_reference || "",
      otp: params.otp,
      internalVerification: {
        otpVerification: params.otpVerification || false,
        otp: params.otp || "",
      },
      ekoCreateCustomer: {
        otp_ref_id: params.otp_reference,
        otp: params.otp,
        skipverification: "true",
      },
    }),
  }
);
export const verifyOTPCustomer = createEncryptedAsyncThunk(
  "beneficiary/verifyOTPCustomer",
  "transaction/CreateCustomer",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      customerMobileNo: params.mobile,
      customerFirstName: params.fname,
      customerLastName: params.lname,
      dob: "1992-12-20",
      address: {
        country: "INDIA",
        city: "Mumbai",
        // state: params.selectState.label,
        // pincode: params.pincode,
        // district: params.selectState.label,
        state: "Maharashtra",
        pincode: "453345",
        district: "Thane",
        area: "Thane",
      },
      skipverification: "",
      email: "example@gmail.com",
      serviceId: params.serviceId.serviceId,
      // verification: {
      //   otpVerification: true,
      //   otp: "",
      // },
      // paysprintCreateCustomer: {
      //   otp: "",
      //   gst_state: "",
      //   stateresp: "",
      //   bank3_flag: "",
      // },
      // billavenueCreateCustomer: {
      //   txn_type: "",
      //   otp: "",
      // },
      internalVerification: {
        otpVerification: true,
        otp: params.OTP === "" ? "" : params.OTP,
      },
      // ekoCreateCustomer: {
      //   otp: params.OTP === "" ? "" : params.OTP,
      //   skipverification: "false",
      // },
    }),
  }
);
export const RegisterRemitter = createEncryptedAsyncThunk(
  "beneficiary/RegisterRemitter",
  "transaction/RegisterRemitter",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      mobile: params.Mobile.customerMobileNumber,
      firstname: params.fName,
      lastname: params.lName,
      address: params.address,
      otp: params.otp,
      pincode: params.pincode,
      stateresp: params.stateresp,
      bank3_flag: "No",
      dob: "1991-12-20",
      gst_state: `${params.selectState.value}`,
    }),
  }
);
export const getCustomerDetails = createEncryptedAsyncThunk(
  "beneficiary/getCustomerDetails",
  "account/GetCustomerList",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      cust_mobileNo: params.customerMobileNumber.customerMobileNumber,
      api_id: params.serviceId.api_id,
    }),
  }
);
export const getQuerryRemitter = createEncryptedAsyncThunk(
  "beneficiary/getQuerryRemitter",
  "transaction/QuerryRemitter",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: Number(params.serviceId.serviceId),
      mobile: `${params.SearchNumber}`,
      latitude: `${params.lat}`,
      longitude: `${params.lng}`,
      updateLimit: true,
    }),
  }
);
// export const getBeneficiaryList = createEncryptedAsyncThunk(
//   "beneficiary/getBeneficiaryList",
//   "transaction/FetchBeneficiary",
//   {
//     transUrl: process.env.REACT_APP_API_TRANSACTION,
//     prepareBody: (params) => ({
//       // userID: params.currentUser.userGuid,
//       // cust_id: params.customerData.cust_id,
//       userID: params.currentUser.userGuid,
//       mobile: params.beneData.mobileno,
//       serviceId: params.serviceId.serviceId,
//       custid: params.beneData.cust_id,
//       txn_type: "IMPS",
//     }),
//   }
// );
export const getBeneficiaryList = createEncryptedAsyncThunk(
  "beneficiary/getBeneficiaryList",
  "transaction/FetchBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // cust_id: params.customerData.cust_id,
      userID: params.currentUser.userGuid,
      mobile: params.SearchNumber,
      serviceId: Number(params.serviceId.serviceId),
      custid: Number(params.cust_id),
      txn_type: "IMPS",
    }),
  }
);
export const benificiary = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return {
        ...state,
        creditAmountResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, benDeleteSuccess: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = benificiary.actions;
export default benificiary.reducer;
